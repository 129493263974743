import React from "react";
import Landing2Component from "../components/ITStartup/Landing2Component";

const LOutsourcingConAppius = () => {

    const [myDocument, setDocument] = React.useState(null);

    React.useEffect(() => {
        setDocument(document);
    }, []);

    if(!myDocument) return <></>;
    return <Landing2Component id={1}/>
};

export default LOutsourcingConAppius;